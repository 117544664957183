.open-ticket {
  min-width: 510px;
  height: calc(100vh - 68px);
  background-color: #ffffff;
  border-left: 1px solid #d6d6d6;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.no-nav-bar .open-ticket {
  height: 100vh;
}

.number-box {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  width: max-content;
  height: 22px;
  background: #007fff;
  border-radius: 4px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #ffffff;
  margin-left: 10px;
}

.review-section {
  padding: 20px 20px;
  word-wrap: break-word;
}

.review-left-width {
  width: 118px;
}

.mt-15 {
  margin-top: 12px;
}

.subject-section {
  align-items: center;

  /* width: 480px; */
  height: 32px;
  border-bottom: 1px solid #d6d6d6;
}

.add-subject-img {
  width: 80px;
  height: 80px;

  border-radius: var(--4, 4px);
  border: 1px solid var(--white-white-300, #D6D6D6);
  margin-right: 20px;
  margin-top: 20px;
  overflow: hidden;
}

.add-subject-img img {
  object-fit: cover;
}

.file-options {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  top: 0;
  position: absolute;
  background: rgba(249, 249, 249, 0.601);
  padding: 5px;
}

.chat-position {
  background: #F6F6F6;
  backdrop-filter: blur(8px);
  padding-bottom: 5px;
}

.ticket-reply {
  width: 100%;
  height: 29px;
  border: none;
}

.small-vertical-line {
  border-left: 2px solid #d6d6d6;
  height: 100%;
  width: 0;
  padding-right: 13px;
}

.review-property {
  color: rgb(173, 173, 173);
  cursor: copy;
}

.review-property:hover {
  font-weight: 600;
  color: black;
}

/* ////////////////TaskModal//////////////// */

.main-area {
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin: 0;
  /* width: 1340px; */
  /* height: 920; */
}

.right-chat-area {
  /* width: 555px; */
  height: 510px;
  background-color: #fbfbfb;
}

.botmdiv {
  position: absolute;
}

.heading {
  font-size: 24px;
  color: #747474;
  font-weight: 500;
  padding: 19px;
}

.chat-position1 {
  /* border-left: 2px solid rgba(0, 0, 0, 0.11); */
  padding: 10px;
  position: relative;
  /* margin-bottom: 20px; */
}

.antdSelect-containerDiv .ant-select-selection-item {
  font-size: 12px;
  font-weight: 400;
}

.border-left-blue {
  border-left: 2px solid #007fff;
}

/* Demo.css */

.file-drop {
  /* relatively position the container bc the contents are absolute */
  position: relative;
  height: 100%;
  width: 100%;
}

.file-drop>.file-drop-target {
  /* basic styles */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 5px;

  /* horizontally and vertically center all content */
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;

  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;

  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;

  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;

  text-align: center;
}

.file-drop>.file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: none;
  background-color: rgba(0, 0, 0, 0.75);
  box-shadow: none;
  z-index: 50;
  opacity: 1;

  /* typography */
  color: #007fff;
}

.file-drop>.file-drop-target.file-drop-dragging-over-target {
  /* turn stuff orange when we are dragging over the target */
  color: #007fff;
  box-shadow: 0 0 13px 3px #007fff;
}

/* ////////////////Priority & severity////////////// */

.white-background {
  background-color: white !important;
}

.priority-pill {
  border-radius: 20px;
  font-weight: 600;
  width: 80px;
}


.red-background {
  background-color: #fe3c32 !important;
  color: white;
}

.orange-background {
  background-color: #f4bf00 !important;
  color: white;
}

.yellow-background {
  background-color: yellow !important;
}

.blue-background {
  background-color: #0074cc !important;
  color: white;
}

/* //////////////////priority////////////// */

.priority .ant-select-arrow {
  display: none;
}

.priority .ant-select-selection-item {
  /* background-color: transparent; */
  margin: auto;
  padding: 0px 10px !important;
  border-radius: 20px;
  width: 100% !important;
  margin: auto !important;
}

.priority .ant-select-selector {
  border: none !important;
  margin: auto !important;
  padding: 0px !important;
  width: 100% !important;
}

.priority .ant-select:active {
  width: 150px;
}

/* ////////////////Severity/////////////// */

.severity .ant-select:active {
  width: 100px;
}

.severity .ant-select-arrow {
  display: none;
}

.severity .ant-select-selection-item {
  /* background-color: #007fff; */
  margin: auto;
  padding: 0px 10px !important;
  border-radius: 20px;
  width: 100% !important;
  margin: auto !important;
}

.severity .ant-select-selector {
  border: none !important;
  margin: auto !important;
  padding: 0px !important;
  width: 100% !important;
}

.taskDetailsModal .ant-modal-close-x {
  width: 40px;
}

.taskDetailsModal .ant-modal-content,
ant-modal-body {
  border-radius: 6px;
}

.taskDetailsModal .ant-modal-body {
  border-radius: 6px;
}

.justify-content-between {
  justify-content: space-between;
}

.description-editor div {
  width: 100%;
  overflow: hidden;
  /* text-align: center; */
}

.description-editor img {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
  border-radius: 0.5rem;
  /* transform: translate(25%, 0px);
  max-width: 60%; */
}

.task-comment-section {
  border-top: 1px solid #D6D6D6;
  background: #F6F6F6;
  padding-bottom: 15px;
}

.task-reply-section {
  border-top: 1px solid #D6D6D6;
  background: #F6F6F6;
  padding: 10px;
}

.task-modal-reply-section {
  overflow: scroll;
  border: none !important;
}

.task-detail-modal {
  display: flex;
  width: 100%;
  height: 88vh;
}

.modal-comment-section {
  height: 100%;
  display: flex;
  padding: 15px;
  flex-direction: column;
  justify-content: space-between;
  background: #f6f6f6;
  border-left: 1px solid #D6D6D6;
  width: 70%;
}

.task-detail-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.task-detail-content .ant-tabs-nav {
  margin: 0 !important;
}

.description-editor {
  min-height: 5rem;
}

.task-detail-title,
.task-title-input {
  color: #434343;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  word-wrap: break-word;
  padding: 5px 10px;
  border: 1px solid #fff;
  border-radius: 6px;
}

.task-detail-title:hover,
.task-title-input {
  cursor: text;
  border: 1px solid #D6D6D6 !important;
}

@media (max-width: 865px) {

  .task-calendar-row {
    justify-content: space-around;
  }

  .open-ticket {
    width: 100vw;
    min-width: 100% !important;
    height: 100vh;
  }

  .task-detail-section {
    width: 100vw;
    height: 100%;
  }

  .task-nav-header,
  .chat-settings-header,
  .chat-settings-body,
  .user-panel {
    width: 100vw;
  }

  .watchers-row {
    width: 90% !important;
  }

  .mark-as-button {
    display: none;
  }

}

.task-detail-row {
  display: flex;
  margin: 0.5rem 1rem 1.5rem 0.8rem;
}

.task-detail-button {
  display: flex;
  align-items: center;
}

.task-detail-label {
  margin-right: 10px;
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.task-position-container-arrow {
  position: absolute;

  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid #D6D6D6;
  top: 15px;
  left: 85px;
  height: 20px;
  z-index: 200;
}

.task-position-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: white;
  border: 1px solid #D6D6D6;
  top: 35px;
  left: 15px;
  width: 150px;
  z-index: 20;
}

.task-position-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  height: 60px;
  width: 100%;
  cursor: pointer;
}

.task-position-item.selected {
  background: #f4f4f4;
}

.task-position-item:hover {
  background: #f4f4f4;
}

.task-descp-html-override {

  /* General Reset for Consistency */
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  li,
  blockquote,
  pre {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background-color: #f9f9f9;
    color: #333;
    padding: 20px;
  }

  /* Headings */
  h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #0074cc;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #0056a3;
    margin-bottom: 8px;
  }

  h3 {
    font-size: 1.75rem;
    font-weight: bold;
    color: #003d80;
    margin-bottom: 6px;
  }

  h4,
  h5,
  h6 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 4px;
  }

  /* Paragraphs */
  p {
    font-size: 1rem;
    margin-bottom: 15px;
    color: #555;
  }

  /* Lists */
  ul,
  ol {
    margin-left: 30px;
    margin-bottom: 15px;
  }

  li {
    margin-bottom: 5px;
  }

  ul li {
    list-style-type: disc;
  }

  ol li {
    list-style-type: decimal;
  }

  /* Links */
  a {
    color: #0074cc;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    color: #0056a3;
  }

  /* Blockquote */
  blockquote {
    margin: 20px 0;
    padding: 10px 20px;
    background-color: #f1f1f1;
    border-left: 4px solid #0074cc;
    font-style: italic;
    color: #555;
  }

  /* Preformatted Text */
  pre {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 5px;
    overflow-x: auto;
    font-family: "Courier New", monospace;
    color: #333;
  }

  /* Code */
  code {
    background-color: #f4f4f4;
    padding: 2px 4px;
    border-radius: 4px;
    font-family: "Courier New", monospace;
    color: #c7254e;
  }

  /* Tables */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }

  th {
    background-color: #0074cc;
    color: white;
  }

  td {
    background-color: #f9f9f9;
  }

  /* Forms */
  input,
  textarea,
  select,
  button {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }

  button {
    background-color: #0074cc;
    color: white;
    border: none;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056a3;
  }

  /* Images */
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: 15px;
  }

  /* Horizontal Rule */
  hr {
    border: none;
    border-top: 1px solid #ddd;
    margin: 20px 0;
  }

  /* Bold Text */
  strong {
    font-weight: black;
    font-weight: bolder;
  }

  /* Italic Text */
  i,
  em {
    font-style: italic;
  }

  /* Underlined Text */
  u {
    text-decoration: underline;
  }

  /* Strikethrough Text */
  s,
  del {
    text-decoration: line-through;
  }

  /* Superscript */
  sup {
    font-size: 0.75em;
    vertical-align: super;
  }

  /* Subscript */
  sub {
    font-size: 0.75em;
    vertical-align: sub;
  }

}