.pricing-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    height: 100%;
    width: 100%;
}

.pricing-title {
    font-size: 50px;
    font-weight: 600;
    line-height: 81.52px;
    letter-spacing: 0em;
    text-align: center;
    justify-content: center;
    display: flex;
    color: #002337;
    padding: 0 10px;
}

.pricing-table {
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    max-width: 100%;
    margin-bottom: 1vh;
}

.pricing-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 300;
    width: 350px;
    padding: 60px, 60px, 80px, 60px;
    margin: 10px;
    border-radius: 10px;
    font-size: 14px;
    transition: all 0.3s ease;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    background: #fff;
    color: #002337;
}

.pricing-column:hover {
    background-color: #002337;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    color: #fff
}

.user-quanitity-select {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 300;
    width: 495px;
    padding: 60px 60px 60px 60px;
    margin: 10px;
    border-radius: 8px;
    height: fit-content;
    font-size: 14px;
    background: #fff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}


.details-list {
    margin-bottom: 80px;
}

.price {
    font-size: 2em;
    margin: 1vh 0;
    font-weight: 600;
}

.price-checkout {
    font-size: 40px;
    font-weight: 900;
    margin: 1vh 0;
    color: #2D2D2D;
    margin: 60px;
    text-align: center;
}

.member-price-text {
    margin-top: 5px;
    font-size: 12px;
    color: #2D2D2D;
}

h2 {
    margin-top: 50px;
    margin-bottom: 0px;
    color: #2D2D2D;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 5px;
}

hr {
    margin-top: 50px;
    width: 75%;
    opacity: 0.4;
}

.pricing-toggle-container {
    background-color: #fff;
    border-radius: 8px;
    display: inline-flex;
    padding: 4px;
    width: fit-content;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

.price-toggle {
    padding: 8px 16px;
    cursor: pointer;
    text-align: center;
    flex: 1;
    border-radius: 8px;
    font-weight: 500;
    color: #555;
    transition: all 0.3s ease;
}

.price-toggle.selected {
    background-color: #0473cc;
    /* Active tab color */
    color: #fff;
    /* Active text color */
}

.pricing-button-primary {
    background-color: rgb(22, 137, 225);
    border: 2px solid rgb(22, 137, 225);
    padding: 10px 4px;
    border-radius: 6px;
    color: white;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    width: 130px;
    display: flex;
    justify-content: center;
}

@media (max-width: 768px) {

    .pricing-table {
        flex-direction: column !important;
    }

    .pricing-column,
    .user-quanitity-select {
        width: 80vw;
    }

    .user-quanitity-select {
        padding: 10px 20px !important;
    }

    .pricing-container {
        justify-content: space-between;
    }

    .pricing-title {
        font-size: 30px;
        line-height: 50px;
    }

    .price-checkout {
        font-size: 20px;
    }
}


@media (max-width: 640px) {

    .price {
        font-size: 1.5em;
    }

    .pricing-button-primary {
        font-size: 12px;
        width: 100px;
        padding: 5px 4px;
    }

}