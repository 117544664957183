/* .ant-avatar-sm{

} */

@media (max-width: 865px) {
  .ai-summary-button {
    display: none;
  }

  .chat-reply-container {
    height: 100vh !important;
    width: 100vw !important;
  }
}

.chat-room-messages {
  height: 100%;
  padding: 1rem 0;
  letter-spacing: 0.2px;
  line-height: 20px;
}

.no-chat-text {
  margin: 20px 10px;
  font-size: 16px;
}

.commentBox {
  width: fit-content;
  height: 36px;
  border: 1px solid #a1a1a1;
  border-radius: 5px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 6px 10px;
  gap: 14px;
  isolation: isolate;
  /* position: absolute; */
  position: absolute;
  right: 10px;
  top: -10px;
  z-index: 99;
}

.hide {
  display: none;
}

.commentSection {
  position: relative;
}

.commentSection img,
.task-reply-section img,
.chat-section img {
  width: 90%;
  object-fit: contain;
}

.comment-hover {
  display: flex;
  /* width: calc(100vW - 410px); */
}

.chat-textarea,
.ticket-reply__control {
  max-height: 55vh;
  min-height: 4rem;
  overflow-y: scroll;
  width: 100%;
  left: 0;
  border: none;
  outline: none;
}

.chat-textarea textarea,
.ticket-reply textarea {
  max-height: 56vh;
  overflow-y: scroll !important;
}

.chat-textarea textarea,
.chat-textarea textarea:focus,
.ticket-reply textarea,
.ticket-reply textarea:focus {
  border: none;
  outline: none;
}

.chat-area {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem 10px 1rem;
  padding: 10px;
  border: 1px solid #d6d6d6;
  background-color: white;
  border-radius: 10px;
}

/*//////////////////////////////////*/

/* subchatArea css */
.main {
  background-color: #161b25;
  width: 261px;
  height: 400px;
  border-radius: 10px;
  font-family: 'Lato';
  font-size: 12px;
}

.header {
  border-bottom: 2px solid white;
  border-color: #5b5858;
  width: 261px;
  height: 50px;
  color: white;
}

.time {
  color: white;
  float: right;
  padding: 12px;
  margin-top: -35px;
  font-size: 8px;
}

.item {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.imag {
  width: 26px;
  height: 26px;
}

.imag1 {
  width: 18px;
  height: 18px;
}

.header-text {
  font-size: 12px;
  font-weight: 700;
  margin-left: 8px;
  margin-top: 4px;
  float: left;
}

.right {
  display: flex;
  float: right;
  margin-top: -45px;
}

.section {
  display: flex;
  flex-direction: row;
  color: white;
  padding-top: 10px;
}

.chat {
  color: white;
  margin-left: 36px;
  font-size: 12px;
  line-height: 14px;
  margin-right: 5px;
}

.text {
  margin-top: -5px;
  line-height: 14px;
}

.reply {
  border-left: 2px solid #0074cc;
  padding-left: 10px;
  margin-left: 10px;
}

.replies {
  color: #0074cc;
  font-size: 8px;
  float: right;
  margin-right: 5px;
  margin-top: -25px;
  padding: 2px;
  display: flex;
  flex-direction: row;
}

.bottom {
  border-top: 2px solid white;
  border-color: #5b5858;
  width: 261px;
  height: 50px;
  color: white;
  margin-top: 50px;
  padding: 10px;
}

.input {
  border-radius: 10px;
  padding: 5px, 10px, 5px, 10px;
  width: 115px;
  margin-right: 30px;
  border: 1px solid #434343;
  background-color: #262b34;
  float: right;
  background: url('../../../public/images/icons/smilie.svg') no-repeat 93px center;
}

.inpt-img {
  float: right;
  margin-left: 250px;
  margin-top: -20px;
}

.cursor-pointer {
  cursor: pointer;
}

.commenter-icon {
  width: 40px;
  height: 30px;
  transform: translate(0px, 4px);
  margin-right: 10px;
}

.commenter-icon img {
  border-radius: 100%;
  margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 20px;
}

.chat-time-stamp {
  font-size: 12px;
  align-self: flex-end;
  color: grey;
  display: flex;
}

.reply-section {
  margin-bottom: 10px;
}

.reply-section .ant-avatar {
  height: 18px !important;
  width: 18px !important;
}

.chat-reply-container {
  height: 92vh;
  background-color: #ffffff;
  border-left: 1px solid #d6d6d6;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-container .chat-reply-container {
  height: 92vh !important;
}

.chat-reply-container .sub-nav-area {
  height: 55px;
}

.suggestion-list {
  box-shadow: none !important;
  border: none !important;
  background: white !important;
  color: blue !important
}

.reply-comment-drawer {
  position: relative;
  padding: 0.5rem;
}

.date-row {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}

.date-row-pill {
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  width: fit-content;
  padding: 2px 7px;

  z-index: 1;
  background: white;
  position: relative;

  font-size: 13px;
  height: 28px;
  line-height: 27px;

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
}

.date-row-line {
  width: 100%;
  border: 1px solid #6b93f385;
  position: absolute;
  bottom: 50%;
  z-index: 0;
}

.comment-row {
  position: relative;
  width: 100%;
  padding-left: 1rem;
  padding-bottom: 0.2rem;
}

.comment-row:hover,
.reply-comment-drawer:hover {
  background-color: rgba(193, 193, 193, 0.07) !important;
}

.chat-content,
.chat-container-content {
  background-color: #fbfbfb
}

.ai-summary-button button {
  margin-left: 20px;
  border-color: #0074cc;
  color: #0074cc;
}

.chat-container-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.reply-line {
  width: 100%;
  height: 1px;
  margin: 0 10px;
  margin-top: 2px;
  background: grey;
}

.chat-section {
  max-height: 66vh;
}

.chat-settings-header {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding: 0 20px;
}

.chat-settings-body {
  padding: 10px 20px;
}

.chat-settings-section-header {
  font-size: 18px;
  font-weight: 700;
  color: #434343;
  margin: 10px 0;
}

.ql-snow .ql-editor blockquote {
  padding: 5px;
}

.chat-room-messages {

  /* General Reset for Consistency */
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  li,
  blockquote,
  pre {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background-color: #f9f9f9;
    color: #333;
    padding: 20px;
  }

  /* Headings */
  h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #0074cc;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #0056a3;
    margin-bottom: 8px;
  }

  h3 {
    font-size: 1.75rem;
    font-weight: bold;
    color: #003d80;
    margin-bottom: 6px;
  }

  h4,
  h5,
  h6 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 4px;
  }

  /* Paragraphs */
  p {
    font-size: 1rem;
    margin-bottom: 15px;
    color: #555;
  }

  /* Lists */
  ul,
  ol {
    margin-left: 30px;
    margin-bottom: 15px;
  }

  li {
    margin-bottom: 5px;
  }

  ul li {
    list-style-type: disc;
  }

  ol li {
    list-style-type: decimal;
  }

  /* Links */
  a {
    color: #0074cc;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    color: #0056a3;
  }

  /* Blockquote */
  blockquote {
    margin: 20px 0;
    padding: 10px 20px;
    background-color: #f1f1f1;
    border-left: 4px solid #0074cc;
    font-style: italic;
    color: #555;
  }

  /* Preformatted Text */
  pre {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 5px;
    overflow-x: auto;
    font-family: "Courier New", monospace;
    color: #333;
  }

  /* Code */
  code {
    background-color: #f4f4f4;
    padding: 2px 4px;
    border-radius: 4px;
    font-family: "Courier New", monospace;
    color: #c7254e;
  }

  /* Tables */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }

  th {
    background-color: #0074cc;
    color: white;
  }

  td {
    background-color: #f9f9f9;
  }

  /* Forms */
  input,
  textarea,
  select,
  button {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }

  button {
    background-color: #0074cc;
    color: white;
    border: none;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056a3;
  }

  /* Horizontal Rule */
  hr {
    border: none;
    border-top: 1px solid #ddd;
    margin: 20px 0;
  }

  /* Bold Text */
  strong {
    font-weight: black;
    font-weight: bolder;
  }

  /* Italic Text */
  i,
  em {
    font-style: italic;
  }

  /* Underlined Text */
  u {
    text-decoration: underline;
  }

  /* Strikethrough Text */
  s,
  del {
    text-decoration: line-through;
  }

  /* Superscript */
  sup {
    font-size: 0.75em;
    vertical-align: super;
  }

  /* Subscript */
  sub {
    font-size: 0.75em;
    vertical-align: sub;
  }

}