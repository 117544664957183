.login-page {
  width: 100%;
  height: calc(100vh - 155px);
  justify-content: center;
  flex-direction: column;
  position: absolute;
}

.login-container {
  border-radius: 10px;
  background: #FFF;
  padding: 60px;
  /* Popup */
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.10);
  z-index: 999;
}

.sign-in-page {
  display: flex;
}

.signin-form {
  width: 580px;
}

.mb-16 {
  margin-bottom: 16px;
}

.inputTag {
  margin-top: 7px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #d6d6d6;
  padding: 10px 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

.inputTag:focus {
  border: 1px solid #0074cc;
  caret-color: #0074cc;
}

.passTag {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #d6d6d6;
  padding: 10px 37px 10px 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

.passTag:focus {
  border: 1px solid #0074cc;
  caret-color: #0074cc;
}

.labelTag {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 114px;
}

.form-heading {
  margin-top: 201px;
  margin-bottom: 60px;
  margin-left: 114px;
}

.button-tag {
  margin-top: 40px;
  margin-bottom: 16px;
  margin-top: 30px;
  margin-bottom: 10px;
  width: 100%;
  height: 55px;
  border-radius: 10px;
  background-color: #0074cc !important;
  border: 1px solid rgb(200, 200, 200);
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.ml-114 {
  margin-left: 114px;
}

.set-icon {
  max-width: 590px;
  padding: 0px;
  position: relative;
}

.password-eye {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.right-text {
  color: white;
  padding-right: 10px;
}

.set-image {
  position: absolute;
  bottom: -80px;
  right: -300px;
  border: 5px solid #434343;
  border-radius: 30px;
}

/* ///////////////check box ///////////////// */
.checkBox {
  position: relative;
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-top: 16.5px;
  margin-bottom: 40px;
  border-radius: 5px;
}

.check-mark {
  visibility: hidden;
}

.mark {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #d6d6d6;
  border-radius: 5px;
}

.checkBox input:checked~.mark {
  background-color: rgb(255, 255, 255);
}

.mark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkBox input:checked~.mark:after {
  display: block;
}

.checkBox .mark:after {
  left: 6px;
  bottom: 3.5px;
  width: 4px;
  height: 10px;
  border: solid #434343;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.check-label {
  margin-left: 14px;
  margin-top: 16px;
}

.d-none {
  display: none;
}

.d-error {
  margin-top: 0px;
  color: #fe3c32;
}

.text-white {
  color: #fff;
}

.font-weight-500 {
  font-weight: 500;
}

.text-lightBlue {
  color: #78bbff;
}

.text-darkGray {
  color: #434343;
}

.text-dark {
  color: #747474;
}

.font-18px {
  font-size: 18px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-100 {
  margin-top: 100px;
}

.forgottenPasswordDiv {
  flex-direction: column;
}

.pos-relative {
  position: relative;
}

.box-size {
  width: 35px;
  height: 35px;
  outline: none;
  border: 1px solid #d6d6d6;
  border-radius: 17px;
  margin-right: 5px;
  padding: 3px;
}

.otp-input {
  width: 20px !important;
  height: 30px;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  color: #434343;
}

.underline {
  text-decoration: underline;
}

.exclamation-error-icon {
  position: absolute;
  top: 0px;
  width: 18px;
  right: 20px;
}

@media only screen and (min-width: 768px) {
  .set-image {
    width: 300px;
    position: absolute;
    top: 250px;
    left: 550px;
    border: 3px solid #434343;
    border-radius: 30px;
  }

  .form-div {
    width: 280px;
  }

  .ml-signUp {
    margin-left: 50px;
  }

  .right-text-div-width {
    width: 300px;
  }

  .m-top {
    margin-top: 80px;
  }

  .pass-position {
    font-family: "Lato";
    font-weight: 500;
    font-size: 14px;
    line-height: 18.75px;
    font-style: normal;
  }

  .box-size {
    width: 45px;
    height: 45px;
    outline: none;
    border: 1px solid #d6d6d6;
    border-radius: 17px;
    margin-right: 10px;
    padding: 10px;
  }

  .otp-input {
    width: 45px !important;
    height: 55px;
    border-radius: 8px;
    font-size: 30px;
    line-height: 32px;
  }
}

@media only screen and (min-width: 992px) {
  .set-image {
    width: 400px;
    position: absolute;
    bottom: 80px;
    left: 550px;
    border: 4px solid #434343;
    border-radius: 30px;
  }

  .form-div {
    width: 400px;
  }

  .ml-signUp {
    margin-left: 50px;
  }

  .right-text-div-width {
    width: 350px;
  }

  .m-top {
    margin-top: 100px;
  }

  .forgottenPasswordDiv {
    flex-direction: row;
    justify-content: space-between;
  }

  .pass-position {
    position: absolute;
    left: 260px;
    font-family: "Lato";
    font-weight: 500;
    font-size: 14px;
    line-height: 18.75px;
    font-style: normal;
  }

  .box-size {
    width: 60px;
    height: 60px;
    outline: none;
    border: 1px solid #d6d6d6;
    border-radius: 17px;
    margin-right: 15px;
    padding: 17px;
  }

  .otp-input {
    width: 55px !important;
    height: 60px;
    border-radius: 9px;
    font-size: 35px;
    line-height: 37px;
  }
}

@media only screen and (min-width: 1200px) {
  .set-image {
    width: 500px;
    position: absolute;
    bottom: 80px;
    left: 550px;
    border: 4px solid #434343;
    border-radius: 30px;
  }

  .ml-signUp {
    margin-left: 50px;
  }

  .right-text-div-width {
    width: 350px;
  }

  .m-top {
    margin-top: 100px;
  }

  .pass-position {
    position: absolute;
    left: 260px;
    font-family: "Lato";
    font-weight: 500;
    font-size: 14px;
    line-height: 18.75px;
    font-style: normal;
  }

  .box-size {
    width: 60px;
    height: 60px;
    outline: none;
    border: 1px solid #d6d6d6;
    border-radius: 17px;
    margin-right: 15px;
    padding: 17px;
  }

  .otp-input {
    width: 60px !important;
    height: 65px;
    border-radius: 11px;
    font-size: 37px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 1400px) {
  .set-image {
    width: 600px;
    position: absolute;
    bottom: 100px;
    left: 585px;
    border: 5px solid #434343;
    border-radius: 30px;
  }

  .ml-signUp {
    margin-left: 85px;
  }

  .right-text-div-width {
    width: 350px;
  }

  .m-top {
    margin-top: 114px;
  }

  .pass-position {
    position: absolute;
    left: 260px;
    font-family: "Lato";
    font-weight: 500;
    font-size: 14px;
    line-height: 18.75px;
    font-style: normal;
  }

  .box-size {
    width: 80px;
    height: 80px;
    outline: none;
    border: 1px solid #d6d6d6;
    border-radius: 17px;
    margin-right: 15px;
    padding: 26px;
  }
}

@media only screen and (min-width: 1600px) {
  .button-tag {
    margin-top: 40px;
    margin-bottom: 16px;
    margin-top: 30px;
    margin-bottom: 10px;
    width: 100%;
    height: 70px;
    border-radius: 10px;
    background-color: #0074cc;
    border: 1px solid rgb(200, 200, 200);
    cursor: pointer;
  }

  .inputTag {
    margin-top: 10px;
    width: 100%;
    height: 70px;
    border-radius: 10px;
    border: 1px solid #d6d6d6;
    padding: 10px 20px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
  }

  .passTag {
    width: 100%;
    height: 70px;
    border-radius: 10px;
    border: 1px solid #d6d6d6;
    padding: 10px 20px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
  }

  .set-image {
    width: 700px;
    position: absolute;
    bottom: 100px;
    left: 585px;
    border: 5px solid #434343;
    border-radius: 30px;
  }

  .form-div {
    width: 579px;
  }

  .ml-signUp {
    margin-left: 114px;
  }

  .right-text-div-width {
    width: 493px;
  }

  .m-top {
    margin-top: 160px;
  }

  .set-image {
    top: 430px;
  }

  body {
    font-size: 18px;
  }

  .pass-position {
    position: absolute;
    left: 420px;
    font-family: "Lato";
    font-weight: 500;
    font-size: 16px;
    line-height: 18.75px;
    font-style: normal;
  }

  .box-size {
    width: 88px;
    height: 88px;
    outline: none;
    border: 1px solid #d6d6d6;
    border-radius: 17px;
    margin-right: 15px;
    padding: 30px;
  }

  .password-eye {
    top: 30px;
    width: 22px;
  }

  .set-icon .exclamation-error-icon {
    top: 25px;
    width: 22px;
  }

  .pos-relative .exclamation-error-icon {
    top: 32px;
    width: 22px;
  }
}

.lock-icon {
  width: 16px;
  margin-top: -3px;
}

.border-red {
  border: 1px solid #fe3c32;
}

.border-red:focus {
  border: 1px solid #fe3c32;
}

.button-tag:hover {
  background-color: #1a8cff;
}

@media (max-width: 776px) {

  .login-container {
    padding: 20px;
    width: 90%;
  }

  .login-page {
    top: 0 !important;
  }
}