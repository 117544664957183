.template-row-selection {
    padding: 10px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #434343;
}

.template-row-selection:hover {
    cursor: pointer;
    color: #747474;
}


.template-row-selection.selected {
    background: #0074cc;
    border-radius: 5px;
    color: white;
}

.team-settings-label {
    color: #434343;
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
}

.team-settings-tab {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 55vh;
}

.settings-general-row {
    justify-content: space-between;
    align-items: center;
    margin-top: 17px;
}

.team-settings-subheader {
    color: #434343;
    font-size: 14px;
    font-weight: 500;
}

.team-settings-description {
    color: #747474;
    font-size: 12px;
    font-weight: 400;
}

.team-settings-dropdown {
    width: 100%;
    border-radius: 7px;
    border: 1px solid #D6D6D6;
    display: flex;
    padding: 10px 12px;
    align-items: center;
    color: #747474;
    font-size: 14px;
    justify-content: space-between;
}

.team-settings-dropdown-label {
    color: #434343;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin: 20px 0 17px 0;
}

.member-setitngs-invited {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;
}

.new-task-modal-container .ant-modal-title {
    max-width: 93%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.new-task-modal input,
.new-task-modal input:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

.modal-button-drop-down {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    height: 26px;
    margin: 5px 0;
    background-color: white;
    margin-right: 0.8rem;
    padding: 0.5rem;
    font-size: 12px;
    border: 1px solid #8c8c8c80;
    color: #8c8c8c;
}

.color-square {
    height: 10px;
    width: 10px;
    background: RGB(243, 147, 107);
    border-radius: 2px;
    margin-right: 0.5rem;
}

.mini-section {
    font-size: 12px;
    background-color: white;
    flex-wrap: wrap;
    white-space: nowrap;
    padding: 3px 10px;
    border: 1px solid #b0afaf80;
    border-radius: 6px;
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.custom-fields-modal {
    width: 100%;
    padding: 10px;
    padding-bottom: 20px;
}

.field-input {
    width: 100%;
    border: 1px solid #8c8c8c80;
    padding: 5px 10px;
    border-radius: 6px;
}

.field-label {
    font-size: 16px;
    font-weight: 900;
    margin-bottom: 5px;
}

.add-option-button {
    cursor: pointer;
    margin-top: 10px;
}

.option-field-row-label {
    width: 100%;
    font-size: 14px;
}

.customfield .ant-checkbox-inner, .ant-checkbox-input {
    transform: scale(0.7)
  }

.option-field-row {
    display: flex;
    align-items: center;
    padding: 5px 0px ;
    border-radius: 6px;
}

.option-field-row:hover {
    background-color: #dfdfdf;
}