.activity-item {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid var(--white-white-300, #D6D6D6);
    padding: 20px 30px;
}

.activity-item:hover {
    background: #e5e4e479;
}

.activity-item.unread {
    background: #cde1f2a0;
}

.activity-item.unread:hover {
    background: #d5e4f1a0;
}

.activity-link {
    font-size: 14px;
    color: #0074cc;
    cursor: pointer;
}

.activity-link:hover {
    color: #0074ccac;
}

.additional-info {
    border-radius: 4px;
    border-left: 1px solid var(--blue-blue-100, #07E);
    background: rgba(0, 119, 238, 0.10);
    padding: 8px 12px;
    font-size: 12px;
    max-height: 100px;
    overflow: hidden;
    margin-bottom: 10px;
}

.additional-info p {
    margin: 0 !important
}