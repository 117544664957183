/* @media only screen and (min-width: 1200px) {
  .emojiModal {
    margin-right: 60%;
  }
} */
.emojiModal {
  top: 0% !important;
  height: 100%;
  padding-right: 56% !important;
}
.emojiModal .ant-modal-content {
  position: absolute;
  bottom: 110px;
}
.emojiModal .ant-modal-close {
  display: none;
}
.emojiModal .ant-modal-header {
  display: none;
}
.emojiModal .ant-modal-footer {
  display: none;
}
.emojiModal .ant-modal-body {
  padding: 0%;
}
.chatDrawerEmojiModal {
  top: 0% !important;
  height: 100%;
  padding-left: 40px !important;
}
.chatDrawerEmojiModal .ant-modal-content {
  position: absolute;
  bottom: 170px;
}
.chatDrawerEmojiModal .ant-modal-close {
  display: none;
}
.chatDrawerEmojiModal .ant-modal-header {
  display: none;
}
.chatDrawerEmojiModal .ant-modal-footer {
  display: none;
}
.chatDrawerEmojiModal .ant-modal-body {
  padding: 0%;
}
.createChatEmojiModal {
  width: 350px !important;
  margin-top: 4.5% !important;
  margin-left: 62% !important;
}
.createChatEmojiModal .ant-modal-close {
  display: none;
}
.createChatEmojiModal .ant-modal-header {
  display: none;
}
.createChatEmojiModal .ant-modal-footer {
  display: none;
}
.createChatEmojiModal .ant-modal-body {
  padding: 0%;
}
.taskChatAreaEmojiModal {
  top: 0% !important;
  height: 100%;
  padding-left: 40px !important;
}
.taskChatAreaEmojiModal .ant-modal-content {
  position: absolute;
  bottom: 200px;
}
.taskChatAreaEmojiModal .ant-modal-close {
  display: none;
}
.taskChatAreaEmojiModal .ant-modal-header {
  display: none;
}
.taskChatAreaEmojiModal .ant-modal-footer {
  display: none;
}
.taskChatAreaEmojiModal .ant-modal-body {
  padding: 0%;
}
.fullScreenTaskChatAreaEmojiModal {
  width: 350px !important;
  margin-top: 11% !important;
  margin-left: 67% !important;
}
.fullScreenTaskChatAreaEmojiModal .ant-modal-close {
  display: none;
}
.fullScreenTaskChatAreaEmojiModal .ant-modal-header {
  display: none;
}
.fullScreenTaskChatAreaEmojiModal .ant-modal-footer {
  display: none;
}
.fullScreenTaskChatAreaEmojiModal .ant-modal-body {
  padding: 0%;
}
.smileyIcon {
  width: 0px !important;
}
