.drawer .ant-tabs-nav {
    margin: 0;
}

:where(.css-dev-only-do-not-override-bixahu).ant-drawer .ant-drawer-body {
    padding: 0 !important;
}

.drawer .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: lightblue;
    font-weight: 900;
}