.heading-card {
  box-sizing: border-box;
  width: 16rem;
  height: auto;
  border-radius: 6px;
  margin: auto;
  margin-bottom: 10px;
}

.card-number {
  justify-content: center;
  align-items: center;
  padding: 0px 8px 0px 8px;
  min-width: 26px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid #d6d6d6;
  border-radius: 35px;
  font-size: 12px;
}

.card-number-1 {
  justify-content: center;
  align-items: center;
  width: 28px;
  min-height: 36px;
  margin-left: 4px;
  padding: 8px 0px 8px 0px;
  border: 1px solid #d6d6d6;
  border-radius: 35px;
}

.task-card {
  padding: 18px;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  width: 16.5rem;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  margin-bottom: 20px;
}

.task-card:active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}

.task-card:hover,
.list-view-card:hover,
.add-task-row:hover,
.show-more-task-row:hover {
  border-color: #0074cc;
}

.task-card .open-calendar {
  background: white !important;
}

.task-btn:hover {
  background: #43434314
}

.placeHolder {
  min-width: 18rem;
  height: 166px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  margin-bottom: 20px;
  background-color: rgba(135, 84, 84, 0.105);
}

.hor-line {
  width: 100%;
  height: 1.5px;
  opacity: 0.1;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 15px;
}

.task-image-div {
  width: 120px;
  height: 80px;
  /* border: 1px solid #d6d6d6; */
  border-radius: 4px;
  margin-top: 15px;
  overflow: hidden;
}

.task-btn {
  margin-top: 2vh;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 6px;
}

.btn-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  width: auto;
  /* height: 35px; */
  min-height: 35px;
  /* max-height: 50px; */
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #ffffff;
}

.btn-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 15px;
  width: auto;
  height: 26px;
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #ffffff;
  cursor: pointer;
}

.btn-2:hover {
  border-color: grey;
}

.model-input {
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: 13px 10px;
  width: 100%;
  height: auto;
  border: 1px solid transparent;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 7%);
  border-radius: 6px;
}

.ant-modal-body {
  background-color: #fbfbfb;
}

.ant-notification-notice,
.ant-notification-notice-info,
.ant-notification-notice-closable,
.ant-notification-notice-message {
  background-color: #fbfbfb !important;
  color: #434343 !important;
}

.hide-taskcard-onDrag {
  transition: 0.01s;
  transform: translateX(-9999px);
}

@media (max-width: 865px) {

  .project-metrics {
    overflow-y: scroll;
    height: 95vh;
  }

  .title-cell {
    min-width: 10vw;
    max-height: 20px;
    overflow: hidden;
  }

  .project-metric-row {
    flex-wrap: wrap;
  }

  .project-metric-row .metric-box,
  .metric-box-big {
    margin-bottom: 10px !important;
  }

  .list-view-section {
    width: 15rem !important;
  }

  .heading-card-mobile,
  .task-card {
    width: 270px !important;
  }

  .task-board-container>div:first-of-type {
    padding: 18px !important;
  }

  .team-members,
  .workspace-dashboard-container {
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .team-panel .square-letter-large {
    display: none !important;
  }
}

.section-title {
  max-width: 9rem !important;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
}

.list-view-section {
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 18rem;
  height: auto;
  background: #ffffff;
  border-radius: 6px 6px 0 0;
  box-shadow: 4px -5px 9px rgba(0, 0, 0, 0.07);
  background: white;
}

.list-view-container {
  margin-bottom: 2rem;
}

.list-view {
  background: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
  border-top: none;
  border-radius: 0 0 10px 10px;
}

.list-view-card {
  padding: 10px 18px;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
  background: #ffffff;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.show-more-task-row {
  border-top: 1px solid rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}

.add-task-row {
  border-top: 1px solid rgba(0, 0, 0, 0.07);
  padding: 10px 18px;
  height: 50px;
  font-size: 14px;
}

.add-task-list-view {
  border-radius: 0 0 10px 10px;
}

.task-list-title {
  font-size: 14px;
  margin-left: 15px;

  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.priority-pill {
  min-width: 50px;
  height: 25px;
  border-radius: 30px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-section-dropdown-item {
  font-size: 16px;
  padding: 10px;
}

.team-members {
  display: flex;
  width: 100%;
  height: 75vh;
  justify-content: space-between;
}

.workspace-dashboard-container {
  display: flex;
  width: 100%;
  height: 55vh;
  justify-content: space-between;
}

.members-header {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.team-panel {
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 10px;
}

.member-row {
  padding: 5px;
  border-radius: 6px;
}

.member-row:hover {
  background: rgba(0, 0, 0, 0.07);
}

.project-metrics {
  padding: 20px 0;
}

.project-metric-row {
  display: flex;
  width: 100%;
  margin-bottom: 50px;
  justify-content: space-around;
}

.metric-box,
.metric-box-big {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  width: 100%;
  margin: 0 20px;
  padding: 15px 5px;
  height: 150px;

  border-radius: 10px;
  background: var(--white-white-100, #FFF);
  box-shadow: 10px 10px 15px 0px rgba(63, 39, 102, 0.10);
}

.metric-box-title {
  color: var(--dark-dark-200, #434343);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.metric-box-value {
  color: var(--dark-dark-200, #434343);
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.metric-box-subtitle {
  color: var(--dark-dark-200, #434343);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.metric-box-big {
  height: 350px;
}