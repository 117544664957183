.task-filter {
    position: relative;
    z-index: 1;
    display: inline-block;
    text-align: left;
}

.filter-button {
    color: #606060;
    background: transparent;
    /* border: 2px solid #C6C6C6; */
    font-weight: medium;
    font-size: 16px;
    border-radius: 6px;
    padding: 4px 8px;
    display: inline-flex;
    align-items: center;
}

.filter-button:hover {
    background-color: #d9d9d9;
}

.filter-list .filter-icon {
    height: 26px;
    width: 26px;
    border-radius: 26px;
    fill: #0074cc;
}

.main-menu-filter-list .filter-icon {
    height: 18px;
    width: 18px;
    fill: #0074cc;
}

.filter-checkbox {
    margin-right: 15px;
    border-radius: 2px;
    height: 16px;
    width: 16px;
    border: 2px solid #606060;
}

.filter-menu {
    margin-top: 4px;
    position: absolute;
    left: 20px;
    width: 250px;
    border: 1px solid #C6C6C6;
    border-radius: 6px;
    background: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    overflow: hidden;
    outline: none;
}

.filter-list {
    max-height: 60vh;
    overflow-y: scroll;
}

.filter-dropdown {
    max-height: 443px;
    overflow-y: auto;
}

.filter-item {
    background-color: white;
    color: #606060;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    border: none;
    width: 100%;
    font-size: 14px;
    cursor: pointer;
}

.filter-item:hover {
    background-color: #d9d9d9;
}

.filter-search-input {
    padding: 8px;
    font-size: 14px;
    border: none;
    color: #606060;
    background: white;
    outline: none;
    border-radius: 6px;
    width: 100%;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #C6C6C6;
}


.pill-buttons-container {
    text-align: start;
    padding: 10px;
}

.pill-button {
    display: inline-block;
    background-color: transparent;
    color: #606060;
    border-radius: 12px;
    border: 2px solid #C6C6C6;
    padding: 4px 8px;
    font-size: 14px;
    align-items: center;
    display: inline-flex;
    margin: 4px;
    cursor: pointer;
    text-align: center;
}

.pill-button:hover {
    background-color: #d9d9d9;
}

.popup {
    position: fixed;
    width: 20%;
    height: 20%;
    border-radius: 30px;
    background: #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    /* Ensure it appears on top of other content */
}

.popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
}

.popup button {
    margin-top: 10px;
}