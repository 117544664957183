.select-workspace-page {
    background: url("../../../public/images/icons/blueRectangle.svg");
    background-size: 2000px;
    background-repeat: no-repeat;
    background-position: 100% calc(60vh - 155px);
    height: 100vh;
    overflow: hidden;
}

.select-workspace-container {
    display: flex;
    width: 100%;
    height: calc(120vh - 155px);
    flex-direction: column;
    align-items: center;
}

.select-workspace-card {
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--white-white-250, #F3F3F3);
}

.select-workspace-row {
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
}

.select-workspace-row.selected,
.select-workspace-row:hover {
    background: #F1F8FE;
}

.select-workspace-row:hover .workspace-invite-buttons {
    display: block;
}

.select-workspace-p {
    color: var(--dark-dark-100, #747474);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 40px;
}

.select-workspace {
    width: 100%;
    height: calc(60vh - 157.5px);
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.10);
    overflow-y: scroll;
    overflow-x: hidden;
}

.select-workspace-title {
    color: var(--dark-dark-200, #434343);
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 500px;
    margin-bottom: 40px;
}

.single-workspace-card {
    display: flex;
    height: 60px;
    width: 100%;
    border-radius: 9px;
    background: var(--white-white-100, #FFF);
    box-shadow: 0px -1px 15px 0px rgba(0, 0, 0, 0.04), 0px 1px 15px 0px rgba(0, 0, 0, 0.04);
    cursor: pointer;
    margin-top: 20px;
}

.workspace-open-button {
    float: right;
    display: flex;
    width: 90%;
    height: 50px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
}

.select-workspace-container {
    height: 100vh;
}

@media (max-width: 865px) {
    .select-workspace-title {
        width: auto;
        font-size: 20px;
    }

    .select-workspace {
        width: 90%;
    }

    .select-workspace {
        padding: 20px;
        height: calc(100vh - 157.5px) !important;
    }

    .single-workspace-card, .single-workspace-card .text-blue-primary {
        margin: 0;
    }
}