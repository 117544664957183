.sideNavBar {
  background-color: #27282D;
  color: #ffffff;
  height: 100vh;
}

.side-bar-container {
  font-size: 16px; /* Adjust font size as needed */
  font-weight: normal; /* Use normal or bold depending on the importance of the text */
  color: #FFFFFF; /* Choose a high-contrast color for readability */
  text-rendering: optimizeLegibility; /* Improve text rendering for better readability */
  -webkit-font-smoothing: antialiased; /* Smooth font edges on WebKit browsers */
  -moz-osx-font-smoothing: grayscale; /* Smooth font edges on macOS */
  line-height: 1.5; /* Ensure adequate line spacing for readability */
}

.quick-side-menu {
  display: flex;
  flex-direction: column;
  width: 70px;
  height: 100vh;
  background: #323337;
  align-items: center;
  padding-top: 1rem;
  user-select: none;
}

.quick-side-menu .side-nav-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 8px 1.5rem;
}

.quick-side-menu .navLinks {
  font-size: 12px !important;
  color: white;
  padding-left: 0 !important;
}

.quick-side-menu .side-nav-item:hover,
.quick-side-menu .side-nav-item.selected {
  background-color: #0074cc;
  border-radius: 10px;
}

.project-section {
  border-left: 1px solid #fff;
  margin-left: 16px !important;
  padding-left: 10px;
}

.create-project-button {
  padding: 3px 5px;
  border-radius: 10px;
  font-size: 0.8rem;
  margin-top: 4px;
}

.border-none {
  border: none !important;
}

.sideNavBar-collaps {
  background-color: #161b25;
  color: #ffffff;
  height: 100vh;
  width: 66px;
  display: flex;
  align-items: revert;
  flex-direction: column;
}

.nav-section {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  width: 100%;
}

.nav-section img {
  cursor: pointer;
  margin: 0 10px;
}

.nav-section-collaps {
  padding-left: 22px;
  padding-top: 28px;
  padding-bottom: 4px;
  border-bottom: 2px solid #5d5e60;
}

.nav-section h1 {
  color: #ffffff;
  font-weight: 600;
  font-size: 24px;
  padding-bottom: 20px;
}

.navLinks {
  padding-left: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.ant-menu,
.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  background-color: #161b25 !important;
  color: #ffffff !important;
  font-weight: 400;
  font-size: 18px;
  font-family: Lato !important;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  /* color: #ED3934; */
  color: #f4bf01 !important;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #f4bf01 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #161b25;
}

.ant-menu-item-selected,
.ant-menu-submenu-selected {
  color: #f4bf01;
}

.ant-menu-submenu-title,
.ant-menu-item {
  padding-left: 22px !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none !important;
}

/* .ant-menu-sub {
  padding-left: 0px !important;
} */
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  justify-content: center;
}

.search {
  /* padding: 8px 10px; */
  gap: 0px;

  width: 20rem;
  height: 36px;

  border: 1px solid #d6d6d6;
  border-radius: 6px;

  display: block;
  padding: 11px 0px 9px 35px;
  background: transparent url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yLjg2NzE5IDkuNDAwOEMyLjg2NzE5IDUuMzEzNjIgNi4xODExNiAyIDEwLjI2OTUgMkMxNC4zNTc5IDIgMTcuNjcxOSA1LjMxMzYyIDE3LjY3MTkgOS40MDA4QzE3LjY3MTkgMTMuNDg4IDE0LjM1NzkgMTYuODAxNiAxMC4yNjk1IDE2LjgwMTZDNi4xODExNiAxNi44MDE2IDIuODY3MTkgMTMuNDg4IDIuODY3MTkgOS40MDA4Wk0xMC4yNjk1IDBDNS4wNzY5NCAwIDAuODY3MTg4IDQuMjA4NzEgMC44NjcxODggOS40MDA4QzAuODY3MTg4IDE0LjU5MjkgNS4wNzY5NCAxOC44MDE2IDEwLjI2OTUgMTguODAxNkMxMi41MDQ1IDE4LjgwMTYgMTQuNTU3MyAxOC4wMjE5IDE2LjE3MDggMTYuNzE5OEwxOS4xNiAxOS43MDg0QzE5LjU1MDYgMjAuMDk4OSAyMC4xODM4IDIwLjA5ODkgMjAuNTc0NCAxOS43MDg0QzIwLjk2NDkgMTkuMzE3OSAyMC45NjQ5IDE4LjY4NDggMjAuNTc0NCAxOC4yOTQzTDE3LjU4NTcgMTUuMzA2MkMxOC44OTA1IDEzLjY5MjIgMTkuNjcxOSAxMS42Mzc4IDE5LjY3MTkgOS40MDA4QzE5LjY3MTkgNC4yMDg3MSAxNS40NjIxIDAgMTAuMjY5NSAwWiIgZmlsbD0iI0I3QjdCNyIvPgo8L3N2Zz4K") no-repeat 8px 11px;
  background-size: 14px;
  /* background-position-x: 8px;
  background-position-y: 7px; */
}

.search::placeholder {
  color: #b7b7b7;
  opacity: 1;
}

.search:hover {
  background-color: #fdfcfc;
}

.search:focus {
  /* border: 1px solid #B7B7B7 !important; */
  caret-color: #e2e2e2;
}

.search:focus-visible {
  outline: #d6d6d6 auto 1px;
}

.topNavbarLink {
  font-family: "Lato";
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 3px;
  text-align: center;
  color: #5c5c5c;
  margin-right: 15px;
  z-index: 200;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.3s ease-in-out;
}

.topNavbarLink:not(.topNavBar-activeLink):hover {
  color: #aaa;
  font-weight: 500;
}

.topNavbarLink img {
  transition: filter 0.3s ease-in-out;
}

.topNavbarLink:not(.topNavBar-activeLink):hover img {
  filter: invert(1.5) brightness(100%);
}

.navlink-padding {
  padding-right: 18px;
}

.vertical-line {
  border-left: 1px solid #d6d6d6;
  height: 50px;
  margin: 0px 13px;
}

.filter-button,
.sub-nav-filter-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 6px;
}

.filter-button img {
  margin-top: 0.1em;
}

.subnavlinks {
  font-weight: 400;
  font-size: 14px;
  color: #434343;
}

.filter-button:hover,
.sub-nav-filter-button:hover {
  background: #43434314
}

.nav-area {
  width: 100%;
  height: 72px;
  align-items: center;
  padding: 1vh 20px;
  border-bottom: 1px solid #d6d6d6;
  background-color: #ffffff;
}

.sub-nav-area {
  height: 6vh;
  width: 100%;
  align-items: center;
  padding: 0px 20px;
  background: #fbfbfb;
}

.workspace-header {
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nav-icon {
  cursor: pointer;
  margin: 0 10px;
}

.toggle-arrow {
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  position: absolute;
  width: 30px;
  height: 30px;
  right: -1rem;
  top: 1.5rem;
  background: RGB(107, 147, 243);
  border: 1px solid #ffffff;
  border-radius: 48px;
  z-index: 9999999;
}

.toggle-arrow-collapse {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 31px;
  background: RGB(107, 147, 243);
  border: 1px solid #ffffff;
  border-radius: 48px;
  flex: none;
  order: 1;
  left: 55px;
  flex-grow: 0;
  z-index: 1;
}

.no-team {
  font-weight: 600;
  text-align: center;
  padding: 1rem;
  align-self: center;
}

.NavBarContainer {
  padding: 0 10px !important;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100% !important;
}

.NavBarContainer .full-height {
  height: 10px !important;
}

.dropDownCollaps {
  overflow: hidden;
}

.heading-nav {
  font-size: 14px;
  font-weight: 500;
}

.switch-workspace-nav {
  margin-top: 2rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.circle-letter {
  margin-bottom: 10px;
  display: flex;
  width: 40px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8.889px;
  border-radius: 42.667px;
  color: white;
  cursor: 'pointer'
}

.square-letter {
  display: flex;
  width: 18px;
  height: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 10px;
  border-radius: 3px;
  cursor: pointer;
}

.square-letter-large {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 6px;
  margin-right: 10px;
  color: white;
  height: 45px;
  width: 45px;
  font-size: 18px;
}

.cross {
  margin: 28px auto 35px auto;
}

.cross-icon {
  user-select: none;
}

.cross-icon button {
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-bottom: 0.5rem;
  border: none;
  outline: none;
  width: fit-content;
  padding: 0.1rem 1rem;
  border-radius: 1rem;
  background-color: RGB(107, 147, 243);
  color: #e4e4e4;
  letter-spacing: 0.01rem;
}

.nav-item-active,
.nav-item-active:hover {
  background-color: #0074cc !important;
}

.nav-item {
  padding: 3px 10px;
  margin-top: 2px;
  border-radius: 6px;
  height: 38px;
  align-items: center;
}

.nav-bar-chat {
  border-radius: 10px;
  line-height: 20px;
  justify-content: space-between;
}

.nav-item:hover, .nav-bar-chat:hover {
  background-color: #394867;
}

.border-bottom-blue {
  border-bottom-color: #0074cc !important;
}

.topNavBar-activeLink {
  border-bottom: 3px solid #0074cc;
}

.active-chat-border {
  border: 2px solid #0074cc;
  border-radius: 50%;
}

.project-team-icon {
  vertical-align: text-top;
  height: 40px;
}

.nav-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  box-shadow: rgb(255 255 255 / 24%) 0px 1px 4px;
  height: 26px;
  margin: 5px;
}

.nav-settings-icon,
.nav-search-icon {
  padding: 7px;
  background-color: #0074cc;
}

.nav-search {
  position: relative;
}

.search-results {
  position: absolute;
  top:0;
  width: 100%;
  background: white;
  box-shadow: rgb(255 255 255 / 24%) 0px 1px 4px;
  border: 1px solid #d6d6d6;
  z-index: 999999;
  max-height: 60vh;
  border-radius: 10px;
  overflow: hidden;
}

.search-input-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  font-size: 12px;
}

.search-input-row:hover {
  background: #0473cc;
  color: #fff;
}

.project-nav-title {
  color: white;
}

.search-task-title {
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  max-width: 80%;
}

.new-task-nav-button,
.modal-secondary-button,
.modal-primary-button,
.modal-danger-button {
  align-self: center;
  background-color: #0074cc;
  color: white;
  font-weight: 400;
  width: 80%;
}

.modal-danger-button {
  background: red;
}

.modal-danger-button:hover {
  background: rgb(215, 3, 3);
}

.new-task-nav-button:hover,
.nav-settings-icon:hover,
.nav-search-icon:hover {
  opacity: 0.8;
}

.create-team-nav-button {
  background: #936BF3;
}

.create-team-nav-button:hover {
  background: #a387e5;
}

.toggle-dropdown {
  justify-content: center;
  padding: 0.5em 0;
  width: 10rem;
  justify-content: space-between;
}

.project-setting-icons {
  position: absolute;
  left: -1.5rem;
  top: 1.8rem;
  display: flex;
  background: white;
  border-radius: 10px;
}

.mobile-back-button {
  display: none;
}

@media (max-width: 865px) {

  .mobile-back-button {
    display: block;
  }

  .nav-area {
    width: 100% !important;
    height: 9vh;
    flex-wrap: wrap;
    overflow-x: scroll;
  }

  .nav-area .square-letter-large,
  .easy-filters, .project-share-container .ant-avatar-group, .nav-tooltip {
    display: none !important;
  }

  .nav-search {
    display: none;
  }

  .side-bar-container {
    height: fit-content !important;
  }
}

.tooltip-notification-bubble {
  position: absolute;
  top: 0px;
  right: 5px;
  background: #FE3C32;
  border-radius: 10px;
  width: 18px;
  height: 18px;
  text-align: center;
  font-size: 12px;
}

.notification-icon {
  height: 30px;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
}

.nav-dropdown-large {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  width: 250px;
}

.nav-dropdown-large .circle-letter {
  width: 30px;
  height: 30px;
}

.nav-dropdown {
  padding: 6px;
  display: flex;
  align-items: center;
  width: 250px;
  font-size: 16px;
}


.workspace-dropdown-menu .ant-dropdown-menu {
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 15px;
  border: 1px solid var(--white-white-300, #D6D6D6);
  background: var(--white-white-100, #FFF);
}

.workspace-dropdown-menu .ant-dropdown-menu-item:hover {
  border-radius: 6px;
}

.team-tooltip-row,
.project-tooltip-row {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1rem 0.5rem;
  width: 95%;
  margin-bottom: 5px;
  margin-left: 12px;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
}

.project-tooltip-row {
  max-width: 93%;
  padding: 0.8rem 0.5rem;
  border: none;
}

.project-tooltip-row-text {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nav-tooltip {
  position: absolute;
  border: 3px solid white;
  left: -1.5rem;
  height: 400px;
  max-width: 30vw;
  width: max-content;
  border-radius: 10px;
  overflow: scroll;
  background: white;
  font-size: 12px;
  box-shadow: black 0px 1px 4px;
}

.tooltip-chat-row,
.tooltip-team-row {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
}

.tooltip-team-row {
  margin-top: 5px;
  border-top: 1px solid #d6d6d6;
}

.tooltip-chat-last-message {
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  max-width: 15vw;
}

.nav-tooltip-row,
.clear-notifications,
.nav-tooltip-row-viewd {
  display: flex;
  cursor: pointer;
  padding: 1rem 0.5rem;
  width: 45%;
  margin-bottom: 5px;
  margin-left: 12px;
  border-top: 1px solid #d6d6d6;
  background: white;
  border-radius: 6px;
}

.notification-tooltip-content .nav-tooltip-row {
  border-top: 1px solid #c6ddf1bb;
  background: #c6ddf1bb;
}

.notification-tooltip-content .nav-tooltip-row:hover {
  border-top: 1px solid #cde1f2a0;
  background: #cde1f2a0;
}

.nav-tooltip-row-viewd {
  background: white;
}

.team-tooltip-row:hover,
.project-tooltip-row:hover,
.nav-tooltip-row:hover,
.nav-tooltip-row-viewd:hover {
  background: #e5e4e479;
  border-color: #e5e4e479;
}

.notifcation-icon {
  border: 1px solid grey;
  border-radius: 20px;
}

.clear-notifications {
  display: flex;
  justify-content: center;
  cursor: pointer;
  background: #0074cc;
  font-size: 14px;
  padding: 0.5rem;
  margin-top: 5px;
  color: white;
}

.clear-notifications:hover {
  background: #007bff;
  border-color: #007bff;
}

.tooltip-icon {
  border-radius: 10px;
}

.tooltip-notifications-desc {
  padding-top: 5px;
  font-size: 10px;

  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mobile-top-nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d6d6d6;
  height: 60px;
  padding: 15px 10px;
  width: 100vw;
}

.mobile-center-content {
  margin-left: 10px;
}

.mobile-chat-dropdown {
  display: flex;
  align-items: center;
}

.team-title-mobile {
  width: 62vw;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.easy-filters {
  display: flex;
  align-items: center;
}

.mobile-notification-bubble {
  position: absolute;
  top: -3px;
  left: 12px;
}