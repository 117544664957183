@import './navbar.css';
@import './global.css';
@import './taskDrawer.css';
@import './dashboard.css';
@import './chatRoom.css';
@import './signIn.css';
@import './emailConfirmation.css';
@import './teamDrawer.css';
@import './modal.css';
@import './activity.css';
@import './product.css';
@import './pricing.css';
@import '../../src-new/assets/style/drawer.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300&display=swap');
@font-face {
  font-family: 'Lato';
  src: local('Lato'),
    url('../fonts/Lato/Lato-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato-Bold';
  src: local('Lato-Bold'),
    url('../fonts/Lato/Lato-Bold.ttf') format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family: Lato !important;
}

::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius:1rem;
}
