.product-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 10px 50px;
    border-bottom: 1px solid #d6d6d6;
    background: white;
    font-size: 14px;
    position: fixed;
    width: 100vw;
    z-index: 99999;
}

.launch-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: rgb(255 255 255 / 24%) 0px 1px 4px;
    height: 26px;
    padding: 15px 10px;

    align-self: center;
    background-color: rgb(0, 116, 204);
    color: white;
    font-weight: 400;
    width: 80%;
}

.launch-button:hover {
    background-color: rgb(22, 137, 225);
}

.product-container {
    width: 100vw;
    background: #fafafa;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.product-sections {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow-y: scroll;
    padding-top: 5vh;
}

.product-main-section {
    display: flex;
    margin: 50px 0;
}

.product-main-section h3 {
    margin-bottom: 50px;
}

.product-blue-section-top {
    border-radius: 60px 60px 0 0;
}

.product-light-blue-section {
    background-color: rgb(0 116 204 / 58%) !important;
    box-shadow: 5px 0px 0px rgb(0, 116, 204) !important;
}

.product-blue-section, .product-blue-section-top, .product-light-blue-section {
    width: 90vw;
    padding: 30px 45px;
    background-color: rgb(0, 116, 204);
    color: white;
    box-shadow: 5px 0px 0px rgb(0 116 204 / 58%)
}

.product-button-primary,
.product-button-secondary {
    background-color: rgb(22, 137, 225);
    border: 2px solid rgb(22, 137, 225);
    padding: 10px 15px;
    border-radius: 6px;
    color: white;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}

.product-button-secondary {
    color: rgb(22, 137, 225);
    background: white;
}

.product-button-primary:hover,
.product-button-secondary:hover,
.product-link-button:hover {
    opacity: 0.7;
}

.product-blue-section .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background:  rgb(0 116 204 / 58%) !important;
}

@media (max-width: 865px) {

    .product-nav {
        padding: 10px;
    }
}