#root {
  background: #fbfbfb;
}

html, body {
  overscroll-behavior: none;
  height: 100%;
  margin: 0;
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ql-mention-list-container {
  width: 270px;
  height: 200px;
  overflow-y: auto;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
  z-index: 9001;
}

.ql-mention-list-item {
  cursor: pointer;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  padding: 0 20px;
  vertical-align: middle;
}

.ql-mention-list-item.selected {
  background-color: #d3e1eb;
  text-decoration: none;
}

.mention {
  color: rgb(39, 109, 201);
  padding: 0 2px;
}

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.sub-container {
  display: flex;
  justify-content: space-between;
}

.ant-switch {
  background: rgba(0, 119, 238, 0.124) !important;
}

.ant-switch.ant-switch-checked {
  background: #07E !important;
}

blockquote,
pre {
  border-radius: 4px;
  border-left: 1px solid var(--blue-blue-100, #07E);
  background: rgba(0, 119, 238, 0.10);
  padding: 8px 12px;
  width: 90%;
}

.container-content {
  display: flex;
  flex-direction: column;

  width: 100%;
  z-index: 0;
  background: 'rgb(251, 251, 251)';
  overflow: hidden;
}

img {
  user-select: none;
}

.chat-content {
  display: flex;
  flex-direction: column;
}

.chat-content {
  justify-content: space-between;
}

.mobile-nav-bar {
  display: none;
  flex-direction: column;
  width: 100%;
  background-color: #161b25;
}

.mobile-nav-bar .main-content {
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 20px;
}

.mobile-nav-bar .sub-content {
  padding: 10px 20px;
  color: white;
  background-color: #202735;
}

.context-menu {
  position: absolute;
  background: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  z-index: 999;
  max-width: calc(100vw - 20px); 
  max-height: calc(100vh - 20px);
}

.icon-container-hover:hover .hover-color{
  filter: invert(.5) sepia(1) saturate(5) hue-rotate(175deg);
}
.context-menu-item {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 20px;
  height: 40px;
  letter-spacing: 0.2px;
  cursor: pointer;
}

.context-menu-item:hover,
.tooltip-chat-row:hover,
.tooltip-team-row:hover,
.tooltip-chat-row-notifications:hover {
  background: rgba(0, 0, 0, 0.07);
}

.home-screen-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 865px) {

  .right-content-container {
    width: 100% !important;
  }

  .home-screen-container {
    display: flex !important;
    flex-direction: column;
    height: 100%;
    margin-bottom: 10rem;
  }
  
  .account-card-row {
    padding: 0.7rem; 
    gap: 0.3rem; 
  }
  
  .container .sideNavBar,
  .create-workspace .right-panel,
  .nav-todays-date,
  .list-editables {
    display: none !important;
  }

  .title-icon-container {
    color: black !important;
  }

  .nav-area {
    padding: 10px 10px 0 10px !important;
  }

  .second-row-nav-area {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50px;
  }

  .second-row-nav-area .ant-avatar {
    height: 24px;
    width: 24px;
  }

  .second-row-nav-area .add-member img {
    display: none;
  }

  .container {
    flex-direction: column;
    overflow-x: hidden;
  }

  .container-content {
    overflow-y: scroll;
  }

  .mobile-nav-bar {
    display: flex !important;
  }

  .nav-search,
  .vertical-line {
    margin: 0 10px !important;
  }

  .create-workspace .left-panel {
    width: 100% !important;
  }

  .home-card-container {
    flex-direction: column;
    height: 100vh;
    margin-top: 1rem;
  }

  .home-card {
    margin: 30px;
  }

  .float-notification {
    height: 31.5rem !important;
    right: 15% !important;
    width: 17rem !important;
    top: 2.5rem !important;
  }

  .notification-content {
    height: 100% !important;
  }

  .logo {
    width: 100% !important;
  }

  .side-panel-open {
    width: 0px !important;
    flex-grow: 1;
  }

  .rocket-icon {
    top: -140px !important;
    right: 0 !important;
  }
}

.d-flex {
  display: flex;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}


.justify-content-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.container-padding {
  padding: 14px 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-y {
  overflow-y: auto;
}

.cal-button {
  background-color: #0074cc;
  border-radius: 7px;
  width: 80px;
  height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  gap: 10px;
  border: none;
}

.font-11 {
  font-weight: 400;
  font-size: 11px;
}

.font-12 {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 12px;
}

.font-12-600 {
  font-family: 'Lato';
  font-weight: 600;
  font-size: 12px;
}

.font-10 {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 10px;
}

.font-14 {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.font-14-500 {
  font-family: 'Lato';
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.font-14-600 {
  font-family: 'Lato';
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #434343;
}

.font-16 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.font-18 {
  font-family: 'Lato';
  font-weight: 600;
  font-size: 18px;
  color: #434343;
}

.font-18-500 {
  font-family: 'Lato';
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #434343;
  font-style: normal;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400;
}

.font-20-500 {
  font-family: 'Lato';
  font-weight: 500;
  font-size: 20px;
  line-height: 23.44px;
  color: #78bbff;
  font-style: normal;
}

.font-16-500 {
  font-family: 'Lato';
  font-weight: 500;
  font-size: 16px;
  line-height: 18.75px;
  color: #434343;
  font-style: normal;
}

.font-16-600 {
  font-family: 'Lato';
  font-weight: 600;
  font-size: 16px;
  line-height: 18.75px;
  color: #434343;
  font-style: normal;
}

.font-18-400 {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #434343;
  font-style: normal;
}

.font-24-500 {
  font-family: 'Lato';
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  font-style: normal;
}

.font-24 {
  font-family: 'Lato';
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #434343;
  font-style: normal;
}

.font-36-500 {
  font-family: 'Lato';
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  color: #434343;
  font-style: normal;
}

.font-36-600 {
  font-family: 'Lato';
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  color: #fff;
  font-style: normal;
}

.font-40 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
}

.font-16-400 {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #434343;
  font-style: normal;
}

.font-20 {
  font-family: 'Lato';
  font-weight: 700;
  font-size: 20px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-14 {
  margin-right: 14px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-18 {
  margin-left: 18px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-auto {
  margin-left: auto;
}

.ml-5 {
  margin-left: 5px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-15 {
  margin-top: 15px;
}

.m10 {
  margin: 10px;
}

.mt50 {
  margin-top: 50px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-13 {
  margin-top: 13px;
}

.mt-4 {
  margin-top: 4px;
}

.home-card {
  height: calc(100vh - 155px);
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
}

.pl-0 {
  padding-left: 0px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-30 {
  margin-bottom: 30px;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.bg-white {
  background-color: #ffffff;
}

.w100 {
  width: 100%;
}

.profile img,
.profile .default-user-avatar {
  border-radius: 100%;
  margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 20px;
}

.scroll-y {
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scroll-y::-webkit-scrollbar {
  display: none;
}

.scroll-x {
  overflow-x: scroll;
  scrollbar-width: 0px;
  -ms-overflow-style: none;
}

/* .scroll-x::-webkit-scrollbar {
  display: none;
} */
.float-chat-icon {
  justify-content: center;
  align-items: center;
  isolation: isolate;
  position: absolute;
  width: 54px;
  height: 54px;
  background: #0074cc;
  box-shadow: -3px 3px 11px rgb(0 0 0 / 16%);
  border-radius: 37px;
  right: 2%;
  bottom: 4vh;
  display: flex;
}

.float-sub-chat {
  justify-content: center;
  align-items: center;
  isolation: isolate;
  position: absolute;
  background: #0074cc;
  box-shadow: -3px 3px 11px rgb(0 0 0 / 16%);
  border-radius: 37px;
  right: 100px;
  bottom: 30px;
  display: flex;
}

.float-calendar {
  justify-content: center;
  align-items: center;
  isolation: isolate;
  position: absolute;
  width: 478px;
  min-height: 640px;
  background: #ffffff;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  right: 30px;
  top: 66px;
  display: flex;
  z-index: 100;
  padding: 20px 0px;
}

.float-chat-number {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  position: absolute;
  width: 22px;
  height: 22px;
  right: 2%;
  bottom: 4vh;
  background: #ffffff;
  border: 1px solid #0074cc;
  border-radius: 74px;
  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 1;
}

.ant-radio-group,
.ant-radio-group-outline,
.ant-radio-group-small,
.ant-picker-calendar-mode-switch {
  display: none;
}

.ant-btn-primary {
  background-color: red !important;
  border: 1px solid #f4d0d0 !important;
}

.display-none {
  display: none;
}

.ant-modal-footer {
  border-top: 1px solid transparent !important;
  border-radius: 0 0 2px 2px;
}

/* container-width................................................... */

.sidwbarOpen {
  width: calc(100vw - 291px);
}

.sidwbarClose {
  width: calc(100vw - 66px);
}

.TicketOpem {
  width: calc(100vw - 801px);
}

.TicketClose {
  width: calc(100vw - 576px);
}

.p-10 {
  padding: 10px 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.text-disabled-gray {
  color: #B7B7B7;
}

.text-disabled-black {
  color: black;
}

.flex-grow1 {
  flex-grow: 1;
}

.myTasks-tabs-div .ant-tabs-top>.ant-tabs-nav::before {
  border-bottom: 1px solid #d6d6d6;
}

.myTasks-tabs-div .ant-tabs-nav-wrap {
  padding: 0px 20px;
}

.myTasks-tabs-div .ant-tabs {
  font-size: 18px;
}

.myTasks-tabs-div .ant-tabs-tab-btn {
  font-size: 18px;
}

/* .myTasks-tabs-div .ant-tabs-content-holder {
  padding: 0px 15px;
} */

/* .myTasks-tabs-div .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 20px;
} */

.butn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  padding: 5px 10px;

  display: inline-block;
  font-weight: 400;

  text-align: center;
  vertical-align: middle;
}

.butn-primary:hover,
.butn-secondary:hover {
  opacity: 0.8;
}

.butn-default {
  color: #000000;
  background-color: transparent;
  border-color: #d6d6d6;
  border: 1px solid #d6d6d6;
  border-radius: 0.25rem;
  cursor: pointer;

  display: inline-block;
  font-weight: 400;

  text-align: center;
  vertical-align: middle;
}

.butn-danger {
  color: #FE3C32;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.butn-secondary {
  color: #434343;
  background-color: white;
  border: 1px solid #D6D6D6;
  border-radius: 0.25rem;
  cursor: pointer;
  padding: 5px 10px;

  display: inline-block;
  font-weight: 400;

  text-align: center;
  vertical-align: middle;
}

.antd-calendar-div .events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.antd-calendar-div .events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.antd-calendar-div .notes-month {
  font-size: 28px;
  text-align: center;
}

.text-center {
  text-align: center;
}

.antd-calendar-div .notes-month section {
  font-size: 28px;
}

.antd-calendar-div .ant-picker-calendar-header {
  display: none;
}

.text-rotated {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
}

.text-rotated-1 {
  writing-mode: tb-rl;
  /* transform: rotate(180deg); */
}

.text-lettuce {
  color: #24cf73;
}

.text-orange {
  color: #f4bf00;
}

.antd-calendar-div .ant-picker-cell {
  border: 1px solid #d6d6d6;
}

.antd-calendar-div .ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 8px 13px;
}

/* .antd-calendar-div
  .ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-calendar-date {
  border-top: 2px solid transparent;
} */
.text-underline {
  text-decoration: underline;
}

.create-chatgroup-Modal-div .ant-modal-header {
  background: #212226;
  border-bottom-color: transparent;
  /* top-left | top-right | bottom-right | bottom-left */
  border-radius: 8px 8px 0 0;
}

.create-chatgroup-Modal-div .ant-modal-title {
  color: #ffffff;
}

.create-chatgroup-Modal-div .ant-modal-footer {
  background: #212226 !important;
  border-radius: 0 0 8px 8px;
}

.create-chatgroup-Modal-div .ant-modal-body {
  border-top: 1px solid #747474;
  border-bottom: 1px solid #747474;
  overflow-y: auto;

  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.create-chatgroup-Modal-div .ant-modal-body::-webkit-scrollbar {
  display: none;
}

.create-chatgroup-Modal-div .ant-modal-content {
  border-radius: 8px;
}

.text-white {
  color: #ffffff;
}

.bg-transparent {
  background-color: transparent;
}

.flex-grow-1 {
  flex-grow: 1;
}

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.ChatGroupModalSearch::placeholder {
  color: #d6d6d6;
  opacity: 1;
}

.bg-primary-blue {
  background-color: #007fff;
}

.myTasks-task:hover {
  background-color: #fbfbfb;
}

.border-radius10 {
  border-radius: 10px;
}

.disable-pointer-events-div {
  pointer-events: none;
  opacity: 0.4;
}

.assignee-dropdown-content-div .dropdown-content {
  background: white;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  border-radius: 5px;
}

.assignee-dropdown-content-div .dropdown-content .ant-dropdown-menu {
  box-shadow: none;
}

.ant-dropdown-placement-bottomLeft.ant-dropdown-show-arrow .ant-dropdown-arrow::before {
  background: white;
}

.assignee-dropdown-content-div .css-13cymwt-control {
  background-color: transparent;
  border-color: #747474;
  border-radius: 4px;
}

.assignee-dropdown-content-div .css-166bipr-Input {
  color: #ffffff;
}

.assignee-dropdown-content-div .css-qbdosj-Input {
  color: #ffffff;
}

.flex-basis0 {
  flex-basis: 0;
}

/* NavBarContainer ModalCSS */
.inp-lbl {
  font-size: 14px;

  font-weight: 600;
}

.mod-inp {
  border-radius: 5px;
  width: 100%;

  margin: 15px 0px;
}

.new-project-Modal-div .ant-modal-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}

.new-project-Modal-div .ant-modal-header {
  border-bottom-color: transparent;
  /* top-left | top-right | bottom-right | bottom-left */
  border-radius: 10px 10px 0 0;
  padding: 30px 0 0 30px
}

.new-project-Modal-div .ant-modal-body {
  padding-right: 0;
}

.new-project-Modal-div .ant-modal-footer {
  border-radius: 0 0 10px 10px;
  padding: 10px 24px 20px 24px;
}

.new-project-Modal-div .ant-modal-content {
  border-radius: 10px;
}

.new-project-Modal-div .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
}

.new-project-Modal-div .ant-modal-close-x {
  font-size: 22px;
  color: #000000;
}

.text-blue-primary {
  color: #007bff;
}

.confirmation-dialog {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.buttons-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 16px;
  margin-left: 8px;
  padding: 10px;
}

.float-notification {
  position: absolute;
  width: 478px;
  height: 41.5rem;

  /* background: lightgray; */
  background: white;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  right: 30px;
  top: 66px;
  z-index: 100;
  padding: 0.7rem;
}

.float-notification-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
  scrollbar-width: 0;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.float-notification-container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.notification {
  padding: 0.7rem;
  margin-bottom: 0.7rem;
  background-color: #cce5ff;
  /* background-color: white; */
  border-radius: 15px;
  cursor: pointer;
}

.viewedNotification {
  padding: 0.7rem;
  margin-bottom: 0.7rem;
  background-color: #e0e0e0;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
}

.notificationTitle {
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 10px;
}

.notificationDescription {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.notificationTime {
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 5px;
}

.taskDetail {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  word-wrap: break-word;
}

/* Define the CSS animation */
@keyframes taskDoneAnimation {
  0% {
    background-color: mediumseagreen;
    /* Start with green background */
  }

  100% {
    background-color: lightgreen;
    /* Restore the initial background color */
  }
}

/* Apply the animation to the task row */
.done-animation {
  animation: taskDoneAnimation 2s ease-in-out;
}

.done-background {
  background-color: #e0ffe0;
}

.done-background:hover {
  background-color: #e0ffe0;
}

.modal {
  display: flex;
}

.sidebar {
  width: 100px;
  padding: 10px;
  background-color: #f5f5f5;
}

.tab-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #e0e0e0;
  border: none;
  border-radius: 4px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tab-button:hover {
  background-color: #d0d0d0;
}

.tab-button.active {
  background-color: #333;
  color: #fff;
}

.main-view {
  flex: 1;
  padding: 20px;
  background-color: #fff;
}

.profile-tab {
  padding: 20px;
}

.user-card-preferences {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.information {
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* Updated gap between label and value */
  padding-right: 30%;
}

.label {
  font-size: 14px;
  font-weight: bold;
}

.value {
  font-size: 16px;
}

.gap {
  height: 10px;
}

.photo {
  position: relative;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.photo:hover .overlay {
  display: block;
}

.overlay-text {
  color: white;
  position: absolute;
  bottom: 10px;
  /* Adjust the desired distance from the bottom */
  left: 0;
  right: 0;
  text-align: center;
}

.sync-button {
  padding: 10px 20px;
  background-color: blue;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.sync-button.loading {
  cursor: not-allowed;
  opacity: 0.7;
}

.sync-button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.connected-span {
  display: flex;
  align-items: center;
  color: darkgreen;
  font-size: 16px;
  font-weight: bold;
}

.connected-span .tick-icon {
  margin-right: 5px;
}

.disconnect-text {
  font-size: 12px;
  cursor: pointer;
  color: grey;
}

.disconnect-text:hover {
  text-decoration: underline;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.hidden {
  display: none;
}

.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.project-modal-dashed-button {
  border: 1.44372px dashed #B7B7B7;
  width: 86px;
  height: 86px;
  border-radius: 21.2346px;
  cursor: pointer;
}

.project-modal-dashed-button.selected {
  background: #0074cc;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.members-section {
  margin: 20px 0 10px 0;
}

.roles-row {
  margin: 15px 0;
  justify-content: space-between;
  align-items: center;
}

.name-container,
.role-container {
  align-items: center;
}

.name-container .circle-letter {
  margin: 0 10px 0 0;
}

/* TEAM DASHBOARD */

.team-dashboard {
  width: 100%;
  justify-content: space-around;
  height: 90vh;
  align-items: center;
  padding: 30px;
}

.team-panel-container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.team-panel-container .left {
  margin-right: 10px;
}

.team-dashboard .team-panel {
  border-radius: 15px;
  border: 1px solid #F3F3F3;
  background: #FFF;
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.team-dashboard .team-header-row {
  align-items: center;
}

.team-dashboard .team-panel-title {
  color: #434343;
  font-size: 20px;
  font-weight: 700;
  margin-right: 15px;
}

.team-dashboard .team-count {
  display: flex;
  padding: 4px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  border: 1px solid #D6D6D6;
  height: 24px;
}

.team-panel-list {
  max-height: 25vh;
  overflow-y: scroll;
  margin: 30px 0;
}

.team-add-text {
  color: #434343;
  font-size: 16px;
  font-weight: 500;
}

.workspace-invite-buttons {
  display: none;
}

.ant-image-preview-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-image-preview-img {
  max-height: 90vh;
}

.workspace-dashboard {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.workspace-seats {
  width: 100%;
  justify-content: space-around;
}

.workspace-seat-number {
  color: #434343;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.workspace-seat-label {
  color: #7A7A7D;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.workspace-teams-table {
  margin-top: 25px;
}

.workspace-teams-table .team-columns {
  justify-content: space-between;
}

.workspace-teams-table .team-columns p {
  color: #434343;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 22%;
}

.workspace-dashboard .ant-table-cell {
  background: none;
  border: none;
}

.workspace-dashboard .ant-table-cell::before {
  display: none;
}

.workspace-dashboard .ant-table-thead {
  color: #434343;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.team-table-data .team-status {
  height: 32px;
  padding: 11px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #E9EAEE;
  background: #FAFAFA;

  color: #434343;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100px;
  cursor: pointer;
}

.team-table-data img {
  width: 22px !important;
}

.create-workspace .left-panel {
  width: 40%;
}

.title-icon-container {
  display: flex;
  align-items: center;
  /* Vertically align items */
  gap: 8px;
  /* Adjust the gap between title and icon as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
}

.title-icon-container img {
  width: 18px !important;
}

.border-none {
  border: none !important;
}

.toggle-switch {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.toggle-switch label {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.slider {
  position: relative;
  width: 40px;
  /* Smaller slider width */
  height: 20px;
  /* Smaller slider height */
  background-color: #ccc;
  border-radius: 10px;
  margin-left: 5px;
  /* Add some spacing between label and slider */
}

.slider::before {
  content: "";
  position: absolute;
  width: 16px;
  /* Smaller toggle button width */
  height: 16px;
  /* Smaller toggle button height */
  background-color: white;
  border-radius: 50%;
  left: 2px;
  top: 2px;
  transition: 0.2s;
}

.toggle-switch input[type="checkbox"]:checked+.slider {
  background-color: #2196F3;
}

.toggle-switch input[type="checkbox"]:checked+.slider::before {
  left: calc(100% - 18px);
  /* Adjust position for smaller toggle button */
}

.side-panel-open {
  width: 300px;
  flex-grow: 2;
}

.suggestion-list {
  max-height: 200px;
  /* Set the maximum height to make it scrollable */
  overflow-y: auto;
  /* Enable vertical scrolling if needed */
  border: 1px solid #ccc;
  /* Add a border to the suggestion list */
  background-color: white;
  /* Set the background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Add a subtle shadow */
}

.suggestion-item {
  display: flex;
  /* Display icon and name side by side */
  padding: 8px;
  align-items: center;
  border-bottom: 1px solid #ccc;
  /* Add a separator between items */
}

.user-icon {
  margin-right: 10px;
  /* Space between the icon and the name */
}

.user-icon img {
  width: 24px;
  /* Set the width of the icon */
  height: 24px;
  /* Set the height of the icon */
  border-radius: 15px;
}

.user-name {
  flex: 1;
  /* Allow the name to take up the remaining space */
}

.MentionsInput__input {
  border: none;
  outline: none;
  height: 50px;
  /* Initial height */
}

.workspace-members {
  flex-wrap: wrap;
}

.notification-icon {
  width: 18px !important;
}

.mobile-header {
  width: 100%;
  height: 8vh;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  background: #161b25;
  color: white;
}

.mobile-shortcut-group {
  padding: 10px 20px;
  background: #202735;
  justify-content: space-around;
}

.mobile-shortcut {
  border-bottom: 1px solid #202735;
  padding: 10px 0;
  color: white;
  align-items: center;
}

.mobile-section-header {
  font-weight: 600;
  font-size: 15px;
}

.mobile-header-workspace {
  font-size: 22px;
  font-weight: 500;
  margin-left: 10px;
}

.mobile-section-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* CSS Styles for the "x" icon and the notification card */
.notification {
  position: relative;
  /* Rest of your existing styles... */
}

.close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  z-index: 1;
  /* Ensure the "x" is above other content */
}

.close-icon:hover {
  color: blue;
  /* Change color on hover (optional) */
}

.summary-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* Align everything to the right */
}

.button-container {
  margin-left: 20px;
  /* Add some margin for spacing */
}

.radio-container {
  display: flex;
  flex-direction: column;
}

.radio-group {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.radio-group label {
  display: flex;
  align-items: center;
}

.radio-group input[type="radio"] {
  margin-right: 5px;
}

.nav-tooltip-row.viewed {
  background: #f1f0f0 !important;
  border-color: #f1f0f0;
}

.ai-container {
  height: 100%;
  background-color: #ffffff;
  border-left: 1px solid #d6d6d6;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

/* CSS for the button container to stack the buttons vertically */
.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* Adjust the gap between buttons as needed */
  position: absolute;
  bottom: 0;
  margin: 10px 1rem;
  padding: 10px;

}

/* CSS for the individual buttons with blue outline and text */
.blue-outline-button {
  background-color: transparent;
  color: blue;
  border: 2px solid blue;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
  text-align: center;
  text-decoration: none;
}

/* Hover state for the buttons */
.blue-outline-button:hover {
  background-color: blue;
  color: white;
}

.full-height {
  height: 100% !important;
}

.project-chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.fileDropDiv {
  position: absolute !important;
  top: 9.3vh;
  right: 0px;
}

.link-button {
  color: #0074cc;
  cursor: pointer;
}

.box-link {
  color: #2563eb;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-editor {
  min-height: 74px;
  max-height: 199px;
}

.large-editor .ql-editor {
  min-height: 160px;
  max-height: 100%;
}

.ql-toolbar.ql-snow {
  border: none !important;
  padding: 0 !important;
}

.mentions-dropdown {
  position: absolute;
  bottom: 76px;
  width: 30vw;
  max-height: 20vh;
  background: white;
  border: 0.5px solid grey;
  border-radius: 10px;
  overflow-y: scroll;
}

.ql-editor {
  font-size: 14px;
}

.calendar-dropdown .ant-picker {
  border: none;
}

.calendar-dropdown .ant-picker-suffix,
.anticon-close-circle {
  font-size: 18px;
}

.container-loader {
  --uib-color: black;
  --uib-speed: 1.4s;
  --uib-stroke: 5px;
  --uib-bg-opacity: .1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8px;
  width: var(--uib-size);
  border-radius: calc(80px / 2);
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.container-loader::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--uib-color);
  opacity: var(--uib-bg-opacity);
  transition: background-color 0.3s ease;
}

.container-loader::after {
  content: '';
  height: 100%;
  width: 100%;
  border-radius: calc(var(--uib-stroke) / 2);
  animation: zoom var(--uib-speed) ease-in-out infinite;
  transform: translateX(-100%);
  background-color: #07E;
  transition: background-color 0.3s ease;
}

@keyframes zoom {
  0 % {
    transform: translateX(-100 %);
  }

  100% {
    transform: translateX(100%);
  }
}

.task-detail-section .ant-avatar-group {
  display: flex;
  flex-wrap: wrap;
}

.rbc-time-header-gutter,
.rbc-time-content {
  display: none !important;
}

.rbc-time-view,
.rbc-time-header {
  height: 100%;
}

.chat-message ul {
  list-style-type: initial
}

.chat-message ol {
  list-style-type: decimal
}

.truncate-inner-html {
  width:100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
}

.users-table th, .users-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
}

.users-table thead {
  background-color: #f4f4f4;
}

.users-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.users-table tr:hover {
  background-color: #f1f1f1;
}

.users-table a {
  color: #3498db;
  text-decoration: none;
}

.users-table-heading {
  font-size: 16px;
  font-weight: 900;
}

.avatar-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-image {
  object-fit: cover;
}

.secondary-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #0074cc;
  border-radius: 14px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-weight: bold;
}

.blue-pill {
  position: relative;
}

.ant-dropdown-menu-submenu-expand-icon {
  display: none;
}

.account-card-row {
  border-bottom-width: 2px;
  border-bottom-color: #F2F2F2;
  padding: 1.25rem; 
  gap: 1rem; 
  display: flex;
  flex-direction: row;
  align-items: center;
}

.account-card {
  margin-right: 1rem;
  border-radius: 1rem;
  background-color: #ffffff;
  width: 100%; 
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
}


.small-circular-button {
  border: 1.5px solid rgba(4, 115, 204, 0.2);
  border-radius: 16px;
  padding: 0 20px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #0473cc;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s;
}

.small-circular-button:hover {
  background-color: rgba(4, 115, 204, 0.1);
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.chat-search-message{
  font-size: 12px;
}
.chat-search-message .highlight {
  background-color: yellow;
  font-weight: bold;
}

.chat-search-message .mention {
  color: #0473cc;
  text-decoration: none;
}

.chat-search-message .box-link {
  color: #004085;
  text-decoration: underline;
}

.default-user-avatar {
  container-type: inline-size;
  container-name: defaultAvatar;
  width: inherit;
  height: inherit;
  display: 'flex';
  flex-shrink: 0;
}

.default-user-avatar-initials {
  color: #fff;
  font-size: 8px;
  font-weight: bold;
}

@container defaultAvatar (min-width: 21px) {
  .default-user-avatar-initials {
    font-size: 14px;
  }
}

@container defaultAvatar (min-width: 34px) {
  .default-user-avatar-initials {
    font-size: 18px;
  }
}

@container defaultAvatar (min-width: 44px) {
  .default-user-avatar-initials {
    font-size: 22px;
  }
}

@container defaultAvatar (min-width: 70px) {
  .default-user-avatar-initials {
    font-size: 36px;
  }
}
