.admin-console {
    display: flex;
    width: 100%;
}

.admin-console-sidebar {
    width: 20vw;
    height: 100vh;
    background-color: #f5f5f5;
    padding: 10px;
}

.menu-item {
    margin: 10px 0;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
}

.dashboard {
    width: 80%;
    padding: 20px;
}