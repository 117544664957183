.team-drawer {
  background-color: #ffffff;
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.ant-drawer-body {
  padding: 0 !important;
  margin: 0 !important;
}

.team-drawer-nav {
  height: 40px;
  padding: 30px;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.team-drawer-nav button {
  display: block;
  padding: 6px;
  background-color: transparent;
  outline: none;
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  cursor: pointer;
}

.drawer-nav-right-flex {
  display: flex;
  gap: 20px;
}

.team-drawer-body {
  height: calc(100% - 100px);
  padding: 0 20px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.search-form {
  width: 100%;
}

.search-form label {
  margin-bottom: 6px;
  display: block;
  font-size: 1rem;
  color: #434343;
}

.search-input {
  width: 100%;
  /* padding: 5px; */
  display: block;
  /* border: 1px solid #dbdbdb; */
  border-radius: 6px;
  outline: none;
}

.participants-cards {
  margin: 20px 0;
}

.participant-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
}

.participant-card img {
  display: block;
}

.participant-card h2,
.participant-card h3 {
  margin: 0;
  padding: 0;
}

.participant-card h2 {
  font-size: 18px;
  color: #434343;
}

.participant-card h3 {
  font-size: 14px;
  color: #747474;
}

.card-left-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.card-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.card-right-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.search-container {
  width: 100%;
}

.watchers-row {
  margin: 9px 0px 15px 15px;
  width: 480px;
}